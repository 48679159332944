import { useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import "../stylesheets/Modal.css";
import { showModal } from "../redux/modalSlice";
export default function Modal({ src, alt }) {
  const dispatch = useDispatch();
  const handleModal = () => {
    console.log(true);
    dispatch(showModal({ state: false, src: "", alt: "" }));
  };
  return (
    <div className="modal" id="modal" onClick={() => handleModal()}>
      <div className="modal-content">
        <span className="close" id="closeModal" onClick={() => handleModal()}>
          <IoClose />
        </span>
        <img src={src} alt={alt} />

        <p className="modal-text">{alt}</p>
      </div>
    </div>
  );
}
