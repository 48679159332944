import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: {
    state: false,
    src: "",
    alt: "",
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.modal.state = action.payload;
    },
  },
});

export const { showModal } = modalSlice.actions;
export default modalSlice.reducer;
