import "../stylesheets/ProjectsTechsList.css";
import { useDispatch, useSelector } from "react-redux";
import technologies from "../data/skillsDatabase";
import { changeHighligtedTech } from "../redux/highligtedTechSlice";
export default function ProjectsTechsList({ moveToProjects }) {
  const selectedTech = useSelector((state) => state.highligtedTech.tech);
  const dispatch = useDispatch();

  const handleShownProjects = (e) => {
    dispatch(changeHighligtedTech(e));
  };

  return (
    <div className="project-techs-list">
      <p
        className={` skill-tittle ${selectedTech === "" ? "active" : "na"}`}
        onClick={() => {
          handleShownProjects("");
          moveToProjects();

        }}
      >
        Todos los proyectos
      </p>
      {technologies.map((tech, index) => (
        <p
          className={`skill-tittle ${
            tech.name === selectedTech ? "active" : "na"
          }`}
          onClick={() => {
            handleShownProjects(tech.name);
            moveToProjects();
          }}
          key={index}
        >
          {tech.name}
        </p>
      ))}
    </div>
  );
}
