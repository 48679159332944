import { useState } from "react";

import("../stylesheets/AnimatedWord.css");

export function AnimatedWord({ addContainer, words, delay }) {
  const [word, setWord] = useState([]);
  const [counter, setCounter] = useState(0);
  const [wordCounter, setWordCounter] = useState(0);

  const renderWord = () => {
    setTimeout(() => {
      setWord([...word, words[wordCounter][counter]]);
      setCounter(counter + 1);

      if (word.length === words[wordCounter].length) {
        setWord([]);
        wordCounter === words.length - 1
          ? setWordCounter(0)
          : setWordCounter(wordCounter + 1);
        setCounter(0);
      }
    }, delay);

    return word.map((e, i) =>
      addContainer === true ? (
        <span className="animated-word" key={i}>
          {e}
        </span>
      ) : (
        <NoTagElement element={e} key={i} />
      )
    );
  };

  function NoTagElement({ element }) {
    return <>{element}</>;
  }

  return <>{renderWord()}</>;
}

export default AnimatedWord;
