import "../stylesheets/AboutMe.css";
import esteban from "../assets/section-images/esteban-picture.jpeg";
import { useSelector } from "react-redux";


function About({ className, trigger }) {
  const lang = useSelector((state) => state.lang.data);

  if (!lang.about) {
    return <section className={className} id="about">Loading...</section> ;
  }
  return (
    <section className={className} id="about">
      <div
        className={
          "about-me container section-container" +
          (trigger ? " about-animation" : "")
        }
      >
        <h1 className="about-title">{lang.about.title}</h1>
        <div className="about-container">
          <div className="about-img">
            <picture className="about-picture">
              <img src={esteban} alt="" />
            </picture>
          </div>
          <div className="about-text">{lang.about.text.map((e,i) => <p key = {"aboutP-"+i}>{e}<br /><br /></p>)}</div>
        </div>
      </div>
    </section>
  );
}

export default About;
