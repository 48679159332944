import webButtonsGen from "../assets/projects-images/generador-de-botones-css.jpg";
import edeptec from "../assets/projects-images/edeptec.png";
import ahorcado from "../assets/projects-images/juego del ahorcado.png";
import toDoList from "../assets/projects-images/to-do-list-vanilla-js.jpg";
import arduinoProjects from "../assets/projects-images/Proyectos con arduino portfolio img.jpg";
import picProjects from "../assets/projects-images/Proyectos con pic portfolio img.jpg";
import visualBasic from "../assets/projects-images/proyectos-visual-basic-portfolio.jpg";
import gallery from "../assets/projects-images/Galeria de diseños.png";
import traslator from "../assets/projects-images/clave-murcielago-a-español.jpg";
import passWordGenerator from "../assets/projects-images/password-generator-interface-2.jpg";
import friendsCardsGame from "../assets/projects-images/friends-cards-game.jpg";
import myPortfolio from "../assets/projects-images/My-portfolio.jpg";
import fccJavascript from "../assets/projects-images/js-algoriths-data-struct-projects.jpg";
import fccCss from "../assets/projects-images/responsivedesign-css-certification-projects.jpg";
import cortesDeLuzTungurahua from "../assets/projects-images/cortes-de-luz-tungurahua.jpg"
import exerciseTracker from "../assets/projects-images/exercise-tracker.jpg"
import nodeMcuController from "../assets/projects-images/node-mcu-controller.jpg"

import technologies from "../data/skillsDatabase";

const projectsEsDb = [
  {
    name:"portfolio",
    title:"Mi portfolio",
    text:"Portfolio personal.",
  },
  {
    name:"visualBasic6",
    title: "Proyectos en Visual Basic 6.0",
    text: "Coleccion de proyectos realizados con Visual Basic programados en Basic",
  },
  {
    name:"buttonsGen",
    title: "Generador de Botones para web",
    text: "Generador de estilos para botones web.",
  },
  {
    name:"edeptec",
    title: "EDEPTEC",
    text: "Blog personal alojado en blogger.",
  },
  {
    name:"juegoAhorcado",
    title: "Juego del ahorcado",
    text: "Juego del ahorcado, desarrollado con React JS",
  },
  {
    name:"todoList",
    title: "To Do List",
    text: "To Do List. Con almacenamiento de tareas en local storage.",
  },
  {
    name:"gallery",
    title: "Galeria de diseños",
    text: "Galeria de diseños realizados con photoshop y canva.",
  },
  {
    name:"traductor",
    title: "Traductor de clave murcielago",
    text: "Traductor de clave murcielago a español y viceversa.",
  },
  {
    name:"passwordGenerator",
    title: "Generador de Contraseñas",
    text: "Generador de contraseñas seguras.",
  },
  {
    name:"fcGame",
    title: "Friends Cards Juego",
    text: "Adaptacion web del juego verdad o reto.",
  },
  {
    name:"fccJavascript",
    title:"Proyectos con JavaScript",
    text:"Incluye todos los proyectos y desafíos completados para la certificación de Algoritmos y Estructuras de Datos de JavaScript en freeCodeCamp.",
  },
  {
    name:"fccCss",
    title:"Proyectos con CSS",
    text:"Incluye todos los proyectos completados para la certificación de Diseño Responsivo con CSS en freeCodeCamp.",
  },
  {
    name:"cortesdeLuzInfo",
    title:"Programación de cortes de energía en Tungurahua",
    text:"Pagina de informacion sobre la programacion de cortes del servicio de energía eléctrica para Tungurahua.",
  },
  {
    name:"exerciseTracker",
    title:"Seguimiento de ejercicios",
    text:"Aplicación práctica para registrar y gestionar ejercicios mediante una API RESTful. Permite crear usuarios, añadir actividades con detalles como duración y fecha, y consultar el historial de ejercicios con filtros.",
  },
  {
    name: "nodeMcuController",
    title:"Controlador NodeMCU",
    text:"Interface para control de microcontroladores NodeMCU.",
  },
].sort((a, b) => a.name.localeCompare(b.name));

const projectsEnDb = [
  {
    name:"portfolio",
    title:"My portfolio",
    text:"Personal portfolio.",
  },
  {
    name:"visualBasic6",
    title: "Projects in Visual Basic 6.0",
    text: "Collection of projects made with Visual Basic, programmed in Basic.",
  },
  {
    name:"buttonsGen",
    title: "Web Button Generator",
    text: "Style generator for web buttons.",
  },
  {
    name:"edeptec",
    title: "EDEPTEC",
    text: "Personal blog hosted on Blogger.",
  },
  {
    name:"juegoAhorcado",
    title: "Hangman Game",
    text: "Hangman game developed with React JS.",
  },
  {
    name:"todoList",
    title: "To Do List",
    text: "To Do List with task storage in local storage.",
  },
  {
    name:"gallery",
    title: "Design Gallery",
    text: "Gallery of designs made with Photoshop and Canva.",
  },
  {
    name:"traductor",
    title: "Murcielago Code Translator",
    text: "Translator for Murcielago code to Spanish and vice versa.",
  },
  {
    name:"passwordGenerator",
    title: "Password Generator",
    text: "Secure password generator.",
  },
  {
    name:"fcGame",
    title: "Friends Cards Game",
    text: "Web adaptation of the Truth or Dare game.",
  },
  {
    name:"fccJavascript",
    title:"JavaScript Projects",
    text:"Includes all projects and challenges completed for the JavaScript Algorithms and Data Structures certification on freeCodeCamp.",
  },
  {
    name:"fccCss",
    title:"CSS Responsive Design Projects",
    text:"Includes all projects completed for the Responsive Web Design certification on freeCodeCamp.",
  },
  {
    name:"cortesdeLuzInfo",
    title:"Energy Cuts Programming in Tungurahua",
    text:"Information page on the power outage schedule for Tungurahua.",
  },
  {
    name:"exerciseTracker",
    title:"Exercise Tracker",
    text:"A handy app for recording and managing workouts using a RESTful API. It allows you to create users, add activities with details such as duration and date, and view workout history with filters."
  },
  {
    name: "nodeMcuController",
    title:"NodeMCU Controller",
    text:"Interface for controlling NodeMCU microcontrollers."
  }

].sort((a, b) => a.name.localeCompare(b.name));

function getSkillInfo(list) {
  return [
    ...list.map((term) => technologies.filter((tech) => tech.name === term)),
  ].flat();
}
const projectsDb = [
  {
    name:"portfolio",
    src: myPortfolio,
    techStack: getSkillInfo(["React", "Redux", "JavaScript"]),
    url: "https://estebancarrillo.edeptec.com",
  },
  {
    name:"visualBasic6",
    src: visualBasic,
    techStack: getSkillInfo(["Visual Basic"]),
    url: "https://www.edeptec.com/p/proyectos-con-visual-basic.html",
  },
  {
    name:"buttonsGen",
    src: webButtonsGen,
    techStack: getSkillInfo(["Nextjs","JavaScript"]),
    url: "https://buttongen.edeptec.com/",
  },
  {
    name:"edeptec",
    src: edeptec,
    techStack: getSkillInfo([
      "HTML",
      "CSS",
      "JavaScript",
      "jQuery",
      "Blogger",
      "Photoshop",
      "Canva"
    ]),
    url: "https://www.edeptec.com",
  },
  {
    name:"juegoAhorcado",
    src: ahorcado,
    techStack: getSkillInfo(["React","JavaScript"]),
    url: "https://ahorcado.edeptec.com",
  },
  {
    name:"todoList",
    src: toDoList,
    techStack: getSkillInfo(["HTML", "CSS", "JavaScript","Bootstrap"]),
    url: "https://estebancarrillog.github.io/to-do-list",
  },
  {
    name:"gallery",
    src: gallery,
    techStack: getSkillInfo(["HTML", "CSS", "JavaScript","Photoshop","Canva"]),
    url: "https://estebancarrillog.github.io/designs-gallery",
  },
  {
    name:"traductor",
    src: traslator,
    techStack: getSkillInfo(["React","JavaScript"]),
    url: "https://clavemurcielago.edeptec.com/",
  },
  {
    name:"passwordGenerator",
    src: passWordGenerator,
    techStack: getSkillInfo(["HTML", "CSS", "JavaScript"]),
    url: "https://passwordgenerator.edeptec.com/",
  },
  {
    name:"fcGame",
    src: friendsCardsGame,
    techStack: getSkillInfo(["Nextjs","JavaScript"]),
    url: "https://friendscards.edeptec.com/",
  },
  {
    name:"fccJavascript",
    src:fccJavascript,
    techStack: getSkillInfo(["HTML", "CSS", "JavaScript"]),
    url:"https://estebancarrillog.github.io/JS-Algorithms-and-Data-Structures"
  },
  {
    name:"fccCss",
    src:fccCss,
    techStack: getSkillInfo(["HTML", "CSS"]),
    url:"https://estebancarrillog.github.io/free-code-camp-css-certification-projects/"
  },
  {
    name:"cortesdeLuzInfo",
    src: cortesDeLuzTungurahua,
    techStack: getSkillInfo(["React", "JavaScript"]),
    url: "https://estebancarrillog.github.io/cortes-de-luz-ambato-info"
  },
  {
    name:"exerciseTracker",
    src: exerciseTracker,
    techStack: getSkillInfo(["Node Js","MongoDB", "Express"]),
    url: "https://github.com/EstebanCarrilloG/boilerplate-project-exercisetracker"
  },
  {
    name: "nodeMcuController",
    src: nodeMcuController,
    techStack: getSkillInfo(["Nextjs","Prisma ORM", "Turso","Tailwind"]),
    url: "https://nodemcucontroller.edeptec.com/"
  }
].sort((a, b) => a.name.localeCompare(b.name));

const projectsListsEsDb = [
  {
    title: "Proyectos con Arduino",
    text: "Coleccion de proyectos realizados con arduino programados en C++",
  },
  {
    title: "Proyectos con PIC",
    text: "Coleccion de proyectos realizados con pic programados en Basic",
  },
];

const projectsListsEnDb = [
  {
    title: "Projects with Arduino",
    text: "Collection of projects made with Arduino, programmed in C++.",
  },
  {
    title: "Projects with PIC",
    text: "Collection of projects made with PIC, programmed in Basic.",
  },
];

const projectsListsDb = [
  {
    src: arduinoProjects,
    techStack: getSkillInfo(["Arduino"]),
    url: "https://www.edeptec.com/p/proyectos-con-arduino.html",
  },
  {
    src: picProjects,
    url: "https://www.edeptec.com/p/proyectos-con-pic.html",
  },
];



export {
  projectsDb,
  projectsListsDb,
  projectsEsDb,
  projectsEnDb,
  projectsListsEsDb,
  projectsListsEnDb,
};
