
import { certificatesSrc } from "../data/certificatesDb";
import "../stylesheets/Certificates.css";
import { useSelector } from "react-redux";
import Certificate from "./Certificate";


function Certificates({ className, trigger }) {
  const data = useSelector((state) => state.lang.data);
  // if (!data.certificates) {
  //   return <section className={className}> Loading...</section>;
  // }

  
  return (
    <section className={className} id="certificates">
      <div className="container section-container certificates-container">
        <h1>{data.certificates?.title}</h1>
        <div className="certificates-items-container">

          {certificatesSrc.map((e,i) => {
            return (
              <Certificate key={e.src} alt={data.certificates?.certificatesAlt[i]} src={e.src} trigger={trigger}></Certificate>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Certificates;
