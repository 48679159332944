import"../stylesheets/Icon.css"

function Icon({name=null,src, alt="",item,reference=""}) {
    return(
        <figure className={`icon-figure${item === name && reference==="" ? " icon-figure--active" : ""}${item === name && reference !== "" ? " icon-figure--tech" : ""}`}>
            <img src={src} alt={alt}/>
        </figure>
    )
    
}

export default Icon