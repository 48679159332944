import colegeTitle from "../assets/certificates-images/titulo-colegio.jpg";
import instituteTitle from "../assets/certificates-images/titulo-instituto.jpg";
// import maschaCertificate from "../assets/certificates-images/certificado-mashca-bots.jpg";
// import innovaProject from "../assets/certificates-images/certificado-proyectos-inovadores.jpg";
import cssCertificate from "../assets/certificates-images/css-certificate-fcc.jpg";
import jsCertificate from "../assets/certificates-images/js-algotithms-and-data-structures.jpg";
import reactCertificate from "../assets/certificates-images/front-end-developer-libraries.jpg";
import backEndNodeCertificate from "../assets/certificates-images/back-end-development-and-apis.jpg";

const certificatesSrc =[
    {
        src: colegeTitle,
    },
    {
        src: instituteTitle,
    },
    {
        src: cssCertificate,
    },
    {    
        src: jsCertificate,
    },
    {
        src: reactCertificate,
    },
    {
        src: backEndNodeCertificate,
    },
]

const certificatesAltEn = [
    "Title of college",
    "Title of institute",
    "CSS certificate from freeCodeCamp",
    "JavaScript algorithms and data structures certificate from freeCodeCamp",
    "Front End Development Libraries certificate from freeCodeCamp",
    "Back End Development and APIs certificate from freeCodeCamp",
]

const certificatesAltEs = [
    "Titulo del colegio",
    "Titulo del instituto",
    "Certificado de CSS de freeCodeCamp",
    "Certificado de JavaScript de freeCodeCamp",
    "Certificado de Front End Development Libraries de freeCodeCamp",
    "Certificado de Back End Development and APIs de freeCodeCamp",
]



export { certificatesSrc, certificatesAltEn, certificatesAltEs };