import { configureStore } from "@reduxjs/toolkit";
import langReducer from "./langSlice";
import modalReducer from "./modalSlice";
import highligtedTechReducer from "./highligtedTechSlice";

export const store = configureStore({
    reducer: {
        lang:langReducer,
        modal:modalReducer,
        highligtedTech:highligtedTechReducer
    }, 
})