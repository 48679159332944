import "../stylesheets/Skills.css";
import SkillBar from "./SkillBar";
import IconsContainer from "./IconsContainer";
import { useState } from "react";
import technologies from "../data/skillsDatabase";
import { useDispatch } from "react-redux";
import { changeHighligtedTech } from "../redux/highligtedTechSlice";

function Skills({ className, trigger }) {
  
  const dispatch = useDispatch();
  const [item, setItem] = useState("");

  const changeIconStyles = (e) => {
    setItem(e);
  };

  const gotToProjects=(e)=>{
    window.location.replace("/#projects");
    dispatch(changeHighligtedTech(e));
  }


  return (
    <section className={className} id="skills">
      <div className="container section-container skills-container">
        <h1>Skills</h1>
        <div className="skills-items-container">
          {technologies.map((e) => (
            <SkillBar
              key={e.alt}
              text={e.name}
              percent={e.percentage}
              changeIconStyles={changeIconStyles}
              gotToProjects={gotToProjects}
            />
          ))}
        </div>
        <IconsContainer trigger={trigger} item={item}></IconsContainer>
      </div>
    </section>
  );
}

export default Skills;
