import "../stylesheets/SkillBar.css"

function SkillBar({text, percent, changeIconStyles,gotToProjects}){
      return(
        <div className="skill-item" onClick={() => gotToProjects(text)} onMouseOver={ () => changeIconStyles(text)} onMouseLeave={() => changeIconStyles(null)}>
          <p className="skill-tittle">{text}:</p>
          <div className="skill-bar">
            <div className="skill-bar-inner" style={{width:percent} }></div>
          </div>
        </div>
    )

}

export default SkillBar