import Icon from "./Icon";
import "../stylesheets/IconsContainer.css";
import technologies from "../data/skillsDatabase";

function IconsContainer({ item, trigger }) {
  return (
    <div
      className={
        "icons-container" + (trigger ? " icons-container-animation" : "")
      }
    >
      {technologies.map((e) => (
        <Icon key={e.name} name={e.name} src={e.url} alt={e.alt} item={item} />
      ))}
    </div>
  );
}

export default IconsContainer;
