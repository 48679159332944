function NavItemButton({ location, text }) {
  return (
    <a
      href={location}
      className="nav__link"
      onClick={() => (document.querySelector(".nav__input").checked = false)}
    >
      {text}
    </a>
  );
}

export default NavItemButton;
