import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tech: "",
};

export const highligtedTechSlice = createSlice({
  name: "highligtedTech",
  initialState,
  reducers: {
    changeHighligtedTech: (state, action) => {
      state.tech = action.payload;
    },
  },
});

export const { changeHighligtedTech } = highligtedTechSlice.actions;
export default highligtedTechSlice.reducer;
