import { useDispatch } from "react-redux";
import { showModal } from "../redux/modalSlice";
export default function Certificate({trigger,src,alt}) {
    const dispatch = useDispatch();
    const handleModal = () => {
        dispatch(showModal({state:true,src:src,alt:alt}));
    
      };
  
    return (
    <div onClick={() => {handleModal()}}
      className={
        "certificate-item" + (trigger ? " certificate-item-animation" : "")
      }
    >
      <img
        className="certificate-item-image"
        src={src}
        alt={alt}
      />
    </div>
  );
}
